import { CountryCode } from 'constants/store';
import { Country } from 'types/Address';
import * as yup from 'yup';
import { MatchOptions } from 'yup/lib/string';
import { Message } from 'yup/lib/types';
import {
  companyNameMessage,
  containDigitMessage,
  emailMessage,
  fileSizeMessage,
  fileTypeMessage,
  maxLengthMessage,
  maximumDateMessage,
  minLengthMessage,
  minimumDateMessage,
  noCapitalMessage,
  noDigitMessage,
  numberMessage,
  phoneMessage,
  postalCodeNumberMessage,
  postalCodeNumberMessageDE,
  postalCodeNumberMessageFR,
  postalCodeNumberMessageLU,
  postalCodeNumberMessageNL,
  requiredMessage,
  vatMessage,
} from '../components/FormValidationMessage/FormValidationMessage';
import FormValidationSettings from '../constants/formValidation';

const {
  articleNumber,
  checkoutRemarks,
  companyName,
  companyPoNumber,
  contractKey,
  deliveryVoucher,
  email,
  firstName,
  giftCard,
  lastName,
  orderCode,
  password,
  phone,
  poBox,
  postalCode,
  regex,
  repairNumber,
  repairRemarks,
  sealID,
  street,
  streetNumber,
  ticketNumber,
  vatNumber,
} = FormValidationSettings.validation;

const optionalCheck = (validation: yup.BaseSchema, optional?: boolean) =>
  optional ? validation : validation.required(requiredMessage);

const stringValidationBase = yup.string().trim();

const validationFunction = (
  min?: number,
  max?: number,
  regularExpression?: RegExp,
  regexValidation?: MatchOptions | Message<{ regex: RegExp }>,
) => {
  const baseValidation = regularExpression
    ? stringValidationBase.matches(regularExpression, regexValidation)
    : stringValidationBase;

  if (min && max) {
    return baseValidation.min(min, minLengthMessage).max(max, maxLengthMessage);
  }

  if (min) {
    return baseValidation.min(min, minLengthMessage);
  }

  if (max) {
    return baseValidation.max(max, maxLengthMessage);
  }

  return baseValidation;
};

export const stringValidation = (optional?: boolean) => optionalCheck(stringValidationBase, optional);

export const numberLengthValidationFunction = (min: number, max: number, optional?: boolean) => {
  const validation = validationFunction(min, max, regex.number, numberMessage);

  return optionalCheck(validation, optional);
};

export const emailValidation = (optional?: boolean) => {
  const emailRegex = new RegExp(email.regex);
  const validation = validationFunction(undefined, password.length.max, emailRegex, emailMessage);

  return optionalCheck(validation, optional);
};

export const passwordValidation = (optional?: boolean) => {
  const validation = validationFunction(password.length.min, password.length.max);

  return optionalCheck(validation, optional);
};

export const userOldPasswordValidation = () => {
  const validation = yup.string().required(requiredMessage);

  return optionalCheck(validation);
};

export const registerPasswordValidation = (optional?: boolean) => {
  const validation = yup
    .string()
    .matches(password.regex.capitals, noCapitalMessage)
    .matches(password.regex.numbers, containDigitMessage)
    .max(password.length.max, maxLengthMessage)
    .min(password.length.min, minLengthMessage);

  return optionalCheck(validation, optional);
};

export const firstNameValidation = (optional?: boolean) => {
  const validation = validationFunction(firstName.length.min, firstName.length.max, firstName.regex, noDigitMessage);

  return optionalCheck(validation, optional);
};

export const lastNameValidation = (optional?: boolean) => {
  const validation = validationFunction(lastName.length.min, lastName.length.max, lastName.regex, noDigitMessage);

  return optionalCheck(validation, optional);
};

export const companyNameValidation = (optional?: boolean) => {
  const validation = validationFunction(
    companyName.length.min,
    companyName.length.max,
    companyName.regex,
    companyNameMessage,
  );

  return optionalCheck(validation, optional);
};

export const booleanValidation = (optional?: boolean) => {
  if (optional) {
    return yup.boolean();
  }

  return yup.boolean().oneOf([true], requiredMessage).required(requiredMessage);
};

export const routeValidation = (optional?: boolean) => {
  const validation = validationFunction(street.length.min, street.length.max);

  return optionalCheck(validation, optional);
};

export const streetNumberValidation = (optional?: boolean) => {
  const validation = validationFunction(streetNumber.length.min, streetNumber.length.max, undefined, numberMessage);

  return optionalCheck(validation, optional);
};

export const poBoxValidation = (optional?: boolean) => {
  const validation = validationFunction(poBox.length.min, poBox.length.max);

  return optionalCheck(validation, optional);
};

export const phoneValidation = (optional?: boolean) => {
  const validation = validationFunction(undefined, undefined, phone.regex, phoneMessage);

  return optionalCheck(validation, optional);
};

export const vatValidation = (optional?: boolean) => {
  const validation = validationFunction(undefined, undefined, vatNumber.regex, vatMessage);

  return optionalCheck(validation, optional);
};

export const remarksValidation = (optional?: boolean) => {
  const validation = validationFunction(repairRemarks.length.min, repairRemarks.length.max);

  return optionalCheck(validation, optional);
};

export const checkoutRemarksValidation = (optional?: boolean) => {
  const validation = validationFunction(checkoutRemarks.length.min, checkoutRemarks.length.max);

  return optionalCheck(validation, optional);
};

export const repairNumberValidation = (optional?: boolean) => {
  const validation = numberLengthValidationFunction(repairNumber.length.min, repairNumber.length.max);

  return optionalCheck(validation, optional);
};

export const postalCodeValidation = (optional?: boolean) => {
  const validation = validationFunction(postalCode.length.min);

  return optionalCheck(validation, optional);
};

export const deliveryVoucherValidation = (optional?: boolean) => {
  const validation = validationFunction(deliveryVoucher.length.min, deliveryVoucher.length.max);

  return optionalCheck(validation, optional);
};

export const articleNumberValidation = (optional?: boolean) => {
  const validation = validationFunction(articleNumber.length.min, articleNumber.length.max);

  return optionalCheck(validation, optional);
};

export const orderCodeValidation = (optional?: boolean) => {
  const validation = validationFunction(orderCode.length.min, orderCode.length.max);

  return optionalCheck(validation, optional);
};

export const contractKeyValidation = (optional?: boolean) => {
  const validation = validationFunction(contractKey.length.min, contractKey.length.max);

  return optionalCheck(validation, optional);
};

export const dateValidation = (optional?: boolean) => {
  const validation = yup.date().nullable().default(undefined);

  return optionalCheck(validation, optional);
};

export const giftCardNumberValidation = (optional?: boolean) => {
  const validation = validationFunction(giftCard.number.length.min, giftCard.number.length.max);

  return optionalCheck(validation, optional);
};

export const giftCardPinValidation = (optional?: boolean) => {
  const validation = validationFunction(giftCard.pin.length.min, giftCard.pin.length.max);

  return optionalCheck(validation, optional);
};

export const giftCardActivationCodeValidation = (optional?: boolean) => {
  const validation = validationFunction(giftCard.activationCode.length.min, giftCard.activationCode.length.max);

  return optionalCheck(validation, optional);
};

export const ticketNumberValidation = (optional?: boolean) => {
  const validation = validationFunction(ticketNumber.length.min, ticketNumber.length.max);

  return optionalCheck(validation, optional);
};

export const sealIDValidation = (optional?: boolean) => {
  const validation = validationFunction(sealID.length.min, sealID.length.max);

  return optionalCheck(validation, optional);
};

export const giftCardMessageValidation = (optional?: boolean) => {
  const validation = validationFunction(undefined, giftCard.message.length.max);

  return optionalCheck(validation, optional);
};

const validationMap: Record<CountryCode, { message: () => JSX.Element; regex: RegExp }> = {
  BE: { message: postalCodeNumberMessage, regex: postalCode.regexBE },
  DE: { message: postalCodeNumberMessageDE, regex: postalCode.regex },
  FR: { message: postalCodeNumberMessageFR, regex: postalCode.regex },
  LU: { message: postalCodeNumberMessageLU, regex: postalCode.regex },
  NL: { message: postalCodeNumberMessageNL, regex: postalCode.regex },
};

export const postalCodeCityValidation = (
  optional?: boolean,
  regex = postalCode.regex,
  message = postalCodeNumberMessage,
) => {
  const validation = validationFunction(undefined, undefined, regex, message);
  return optionalCheck(validation, optional);
};

export const postalCodeCityValidationForCountry = (optional?: boolean, countryFieldName?: string) =>
  countryFieldName
    ? yup.string().when(countryFieldName, (country: Country) => {
        const countryCode = country?.isocode;
        const data = countryCode
          ? validationMap?.[countryCode]
          : {
              message: postalCodeNumberMessage,
              regex: postalCode.regex,
            };

        return postalCodeCityValidation(optional, data.regex, data.message);
      })
    : postalCodeCityValidation(optional);

export const companyPoNumberValidation = (optional?: boolean) => {
  const validation = validationFunction(undefined, companyPoNumber.length.max);

  return optionalCheck(validation, optional);
};

export const fileUploadValidation = (
  optional?: boolean,
  maxFileSizeInBytes = 1024 * 1024 * 50,
  acceptedFileTypes?: string[],
) => {
  const validation = yup
    .mixed<FileList>()
    .nullable()
    .test(
      'fileSize',
      () => fileSizeMessage(maxFileSizeInBytes),
      (files) => !files || Array.from(files).every((file) => file.size <= maxFileSizeInBytes),
    )
    .test(
      'fileTypes',
      () => fileTypeMessage(acceptedFileTypes),
      (files) =>
        !files || Array.from(files).every((file) => (acceptedFileTypes ? acceptedFileTypes.includes(file.type) : true)),
    );

  return optionalCheck(validation, optional);
};

export const deliveryFloorValidation = (optional?: boolean) => {
  const validation = validationFunction();
  return optionalCheck(validation, optional);
};

export const minMaxDateValidation = (minDate?: Date, maxDate?: Date, optional?: boolean) => {
  const validation = yup.date();

  if (minDate && maxDate) {
    return validation.min(minDate, minimumDateMessage).max(maxDate, maximumDateMessage);
  }

  if (minDate) {
    return validation.min(minDate, minimumDateMessage);
  }

  if (maxDate) {
    return validation.max(maxDate, maximumDateMessage);
  }

  return optionalCheck(validation, optional);
};
